.Navbar{
    height: 5rem;
    width: 100vw;
    max-width: 75vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: rgb(229, 229, 229);
}
.logo{
    object-fit: cover;
    height: 4rem;
    width: 15rem;
}
.DesktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
}
.DesktopMenuListItem:hover,.ListItem:hover{
    color: rgb(68, 89, 100);
    padding-bottom: 0.3rem;
    border-bottom: solid rgb(68, 89, 100) 3px;
}
.NavResumebtn,.Navcontactbtn{
    background: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    border: solid black 0.5px;
    margin-right: 2rem;
    padding-right:1.5rem;
    border-radius: 2rem;
    transition: 0.25s;
}
.NavResumebtn:hover,.Navcontactbtn:hover{
    background:rgba(255, 255, 255, 0.537);    

}
.NavButtons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DesktopMenuImg{
    object-fit:cover;
    margin: 1rem;
    height: 1rem;
    width: 1rem;
}
.active{
    color: rgb(68, 89, 100);
    padding-bottom: 0.3rem;
    border-bottom: solid rgb(68, 89, 100) 3px;
}
.mobilenav{
  display: flex;
  flex-direction: column;
  position: absolute;
  top:4rem;
  right: 2rem;
  background:rgb(142, 142, 142,0.1);
  padding:0.5rem ;
  min-width: 15rem;
  border-radius: 10px;

}
.ListItem{
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(200, 200, 200);
    cursor: pointer;
}
.mobmenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
    mix-blend-mode: multiply;
}
@media screen and (max-width:1200px){
    .logo{
        height: 3rem;
    }
    .DesktopMenuListItem{
        font-size: 0.8rem;
    }
    .NavResumebtn,.Navcontactbtn{
        font-size: 0.8rem;
        margin-right: 1vw;
    }
    

}
@media screen and (max-width:768px){
    .DesktopMenu{
        display: none;
    }
    .mobmenu{
        display: flex;
    }
    .NavResumebtn,.Navcontactbtn{
        display: none;
    }
    .Navbar{
        padding-left: 0;
        padding-right: 1rem;
    }
}
@media screen and (max-width:480px){
    .mobilenav{
        min-width: 10rem;
    }
    .Navbar{
        max-width: 100vw;
       
    }


}

