.contact{
    width: 100vw;
    max-width: 75vw;
    margin: 10vh auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contactform{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    width: 70vw;
    max-width: 60rem;
}
.textinput{
    font-size: medium;
    margin: 0.5rem;
    width:100%;
    max-width: 40rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    background-color: white;
    border: solid 0.5px #888888;
    box-shadow: -2px 2px #888888;

}
.submitbtn{
    background: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 4rem 0.5rem 4rem;
    margin: 0.5rem;
    box-shadow: -2px 2px #888888;
    transition: 0.25s;
}
.submitbtn:hover{
    background:rgba(255, 255, 255, 0.537);    
}
.submitbtn:active{
    box-shadow: none;
    transform: translateY(3px);
}
.sociallinklogo{
    width:2rem;
    margin: 1rem;
    transition: 0.25s;
    mix-blend-mode: multiply;
}
.sociallinklogo:hover{
    opacity: 0.5;
}
@media screen and (max-width:480px){
    .contactform{
        margin: 0;
    }  
}