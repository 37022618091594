.About{
    width: 100vw;
    max-width: 65vw;
    margin: 10vh auto;
    text-align: center;
}
.wido{
    text-align: center;
    margin: 5vh;
}
.Aboutpara{
    text-align: center;
    
}
.hskills{
    display: flex;
    justify-content: center;
    font-size:xx-large;
    font-weight: bold;
    margin: 5vh;
}
.skills{
    color: rgb(68, 89, 100);
    margin-left: 5px;
}

.Hexagon{
    left: 0;
    
    width:20px;
    height:11px;
    border-left: 2px solid rgba(68, 89, 100, 2);
    border-right: 2px solid rgba(68, 89, 100, 2);
    position: absolute; 
    display: block;  
}
.Hexagon:before,
.Hexagon:after{
    right: -9%;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-left: 2px solid rgba(68, 89, 100, 2);
    border-right: 2px solid rgba(68, 89, 100, 2); 
}
.Hexagon:before{
    transform: rotate(60deg);
}
.Hexagon:after{
    transform: rotate(-60deg);
}
.HexagonLight,.HexagonLight:before,.HexagonLight:after{
    border-left: 2px solid rgba(68, 89, 100, 0.5);
    border-right: 2px solid rgba(68, 89, 100,0.5); 
}
.skillList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; 
    margin-left: 10%;
}
.skillListItem{
    display: block;
    margin: auto;
    padding: auto;
    margin-bottom: 40px;
    width:250px;
}
.skillrate{
    display: block;
    position: relative;
    width:60px;
}
.skillName{
    text-align: left;
    font-size:x-large;
    margin-bottom:10px;
}
.hco{
    color:rgb(68, 89, 100);
}
.whatiusetitle{
    margin-top: 20px;
}
.techstack{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.techimg{
    mix-blend-mode:multiply;
    height:40px;
    margin:1rem;
}
.techtitle{
    margin-top: 20px;
}
@media screen and (max-width:480px){
    .skillList{
        justify-content: center;
    }
    .wido{
        font-size: large;
    }
    .Aboutpara{
        font-size: medium;
    }
}
.frontend,.backend,.db{
    margin: 0 2rem;
}
