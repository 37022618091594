.Projects{
    width: 100vw;
    max-width: 75vw;
    margin: 10vh auto;
}
.projTitle{
    color:rgb(52, 52, 52);
}
.projtitledesc{

}
.projectslist{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
   

}
.guthublink{
    mix-blend-mode: multiply;
    object-fit:cover;
    margin: 1rem;
    height: 1rem;
    width: 1rem;
}
.gitlink{
    margin-top: 0.5rem;
    height:2rem;
    background: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    border: solid black 0.5px;
    margin-right: 2rem;
    padding-right:1.5rem;
    border-radius: 2rem;
    margin-bottom: 10px;
    box-shadow: -2px 2px #888888;
    transition: 0.25s;
}
.gitlink:hover{
    background:rgba(255, 255, 255, 0.537);    
}
.gitlink:active{
    box-shadow: none;
    transform: translateY(3px);
}
.projectlistitem{
    margin-top: 20px;
    width:260px;
    height: max-content;
    padding: 0.5rem;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: -2px 2px #888888;
    border: solid 1px #888888;
    
}
.projectlistitemimg{
    object-fit: cover;
    width: 100%; 
    height:40% 
}
.projectdescription{
    font-size: small;
}